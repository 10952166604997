<template>
	<div>
		<jy-query ref="form" :model="form">
			<!-- <jy-query-item label="机构:" prop="orgName" label-width="70px">
                <el-input placeholder="请选择" v-model="form.orgName" @focus="xlselectInstitutions"></el-input>
            </jy-query-item> -->
			<jy-query-item label="线路:" prop="routeId" label-width="70px">
				<!-- <el-input v-model="form.routeName" @focus="selectInstitutions"></el-input> -->
				<el-select v-model="form.routeId" placeholder="请选择线路">
					<el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
					</el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item prop="vehicleNo" label="车牌号:">
				<el-input placeholder="请输入" v-model="form.vehicleNo"></el-input>
			</jy-query-item>
			<jy-query-item prop="violationType" label="违规类型:">
				<el-select v-model="form.violationType" placeholder="请选择">
					<el-option v-for="item in typeOptions" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</jy-query-item>
			<jy-query-item label="查询日期:" prop="time" :span="1.5">
				<el-date-picker v-model="form.time" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" :picker-options="pickerOptions" :unlink-panels="true"></el-date-picker>
			</jy-query-item>
			<template slot="rightCol">
				<el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
				<el-button type="primary" @click="resetForm('form')" v-if="btnexist('reset')">重置</el-button>
				<el-row>
					<el-button type="primary" @click="exportList" v-if="btnexist('export')">导出</el-button>
				</el-row>
			</template>
		</jy-query>
		<jy-table :data="dataList" v-loading="loading">
			<jy-table-column type="index" label="序号" width="60"></jy-table-column>
			<jy-table-column prop="routeNo" label="线路编号"></jy-table-column>
			<jy-table-column prop="routeName" label="线路名称"></jy-table-column>
			<jy-table-column prop="orgName" label="所属机构" min-width="120"></jy-table-column>
			<jy-table-column prop="vehicleNo" label="车牌号" min-width="120"></jy-table-column>
			<jy-table-column prop="deviceNo" label="设备ID" min-width="120"></jy-table-column>
			<jy-table-column prop="violationTypeName" label="违规类型" min-width="120"></jy-table-column>
			<!-- <jy-table-column prop="violationValue" label="违规值" :formatter="formarViolationValue"></jy-table-column> -->
			<el-table-column prop="violationValue" label="违规值" class-name="jy_table_column" :formatter="formarViolationValue"></el-table-column>
			<el-table-column prop="violationStandard" label="标准值" class-name="jy_table_column" :formatter="formarViolationValue"></el-table-column>
			<jy-table-column prop="bdLon" label="纬度" min-width="160"></jy-table-column>
			<jy-table-column prop="bdLat" label="经度" min-width="160"></jy-table-column>
			<jy-table-column prop="altitude" label="高程(米)"></jy-table-column>
			<jy-table-column prop="speed" label="速度km/h" min-width="100"></jy-table-column>
			<jy-table-column prop="addr" label="地理位置" min-width="200"></jy-table-column>
			<jy-table-column prop="time" label="时间" min-width="160"></jy-table-column>
			<jy-table-column prop="attachment" label="备注" min-width="160"></jy-table-column>
		</jy-table>
		<jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination>
		<xlinstitutions-tree ref="xlinstitutions" @addInstitutions="xladdInstitutions" url="/system/org/getUserOrgTree" title="选择机构"></xlinstitutions-tree>
		<institutions-tree ref="institutions" @addInstitutions="addInstitutionsLine" url="/waybillOperate/getTree" title="选择路线" :defaultProps="props"></institutions-tree>
	</div>
</template>
<script>
	import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	import xlinstitutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
	import { btnMixins } from "@/common/js/button.mixin";
	export default {
		data() {
			return {
				pickerOptions: {
					// disabledDate(time) {
					//     return time.getTime() > Date.now() - 8.64e7
					// },
				},

				props: {
					children: "children",
					label: "text",
					value: "id",
				},
				form: {
					routeId: "",
					routeName: "",
					orgName: "",
					orgId: "",
					vehicleNo: "",
					violationType: "",
					time: [],
				},
				pageSize: 10,
				total: 0,
				pageIndex: 1,
				// 列表列数据
				dataList: [],

				loading: false,

				typeOptions: [
					{
						value: "",
						label: "全部",
					},
					{
						value: 1,
						label: "超速行车",
					},
					{
						value: 2,
						label: "低速行车",
					},
					{
						value: 3,
						label: "滞站",
					},
					{
						value: 4,
						label: "甩站",
					},
					{
						value: 5,
						label: "车内温度过高",
					},
					{
						value: 6,
						label: "车内温度过低",
					},
					{
						value: 7,
						label: "急刹车",
					},
					{
						value: 8,
						label: "急加速",
					},
					{
						value: 9,
						label: "疲劳驾驶",
					},
					{
						value: 10,
						label: "超载",
					},
					{
						value: 11,
						label: "越界行驶",
					},
					{
						value: 121,
						label: "异常开关门报警(带速开门)",
					},
					{
						value: 122,
						label: "异常开关门报警(车启动时未关车门)",
					},
					{
						value: 123,
						label: "异常开关门报警(站外开门)",
					},
					{
						value: 124,
						label: "异常开关门报警(过站未开门)",
					},
					{
						value: 13,
						label: "超速报警扩展",
					},
					{
						value: 14,
						label: "颠簸",
					},
					{
						value: 128,
						label: "逃生门开关保护盖",
					},
					{
						value: 129,
						label: "应急翼展门",
					},
				],
				routeOptions: [],

				btnRoute: "/admin/detailData/carViolationDetail",
			};
		},
		mixins: [btnMixins],
		components: {
			xlinstitutionsTree,
			institutionsTree,
		},
		created() {
			this.getList();
		},
		activated() {
			this.getRouteList();
		},
		methods: {
			getRouteList() {
				let url = "/baseinforoute/queryCurrentAuthorityRoute";
				this.$http.post(url).then(({ detail }) => {
					this.routeOptions = detail;
				});
			},
			getList() {
				let option = {
					...this.form,
					pageIndex: this.pageIndex,
					pageSize: this.pageSize,
				};
				if (option.violationType == 121) {
					option.violationTypeValue = 1;
					option.violationType = 12;
				} else if (option.violationType == 122) {
					option.violationTypeValue = 2;
					option.violationType = 12;
				} else if (option.violationType == 123) {
					option.violationTypeValue = 3;
					option.violationType = 12;
				} else if (option.violationType == 124) {
					option.violationTypeValue = 4;
					option.violationType = 12;
				}
				if (option.time != null && option.time.length) {
					option.startDate = option.time[0];
					option.endDate = option.time[1];
				} else {
					option.startDate = "";
					option.endDate = "";
				}
				let url = "/stat/ViolationInfo/queryPage";
				this.loading = true;
				this.$http.post(url, option).then(
					(res) => {
						this.loading = false;
						this.dataList = res.detail.list;
						this.total = res.detail.total;
					},
					() => {
						this.loading = false;
					}
				);
			},
			// 查询表格
			oncheck() {
				this.pageIndex = 1;
				this.getList();
				console.log("更新");
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.form.orgId = "";
				this.form.routeId = "";
				this.oncheck();
			},
			// 选择线路树
			xlselectInstitutions() {
				this.$refs.xlinstitutions.init();
			},
			// 上级线路机构查询
			xladdInstitutions(data) {
				// console.log(data)
				this.form.orgId = data.orgId;
				this.form.orgName = data.name;
			},
			selectInstitutions() {
				this.$refs.institutions.init();
			},
			addInstitutionsLine(data) {
				this.form.routeId = data.id;
				this.form.routeName = data.text;
			},
			// 分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.pageIndex = val;
				this.getList();
			},
			//导出
			exportList() {
				let url = "/stat/ViolationInfo/export";
				let option = {
					...this.form,
				};
				if (option.violationType == 121) {
					option.violationTypeValue = 1;
					option.violationType = 12;
				} else if (option.violationType == 122) {
					option.violationTypeValue = 2;
					option.violationType = 12;
				} else if (option.violationType == 123) {
					option.violationTypeValue = 3;
					option.violationType = 12;
				} else if (option.violationType == 124) {
					option.violationTypeValue = 4;
					option.violationType = 12;
				}
				if (option.time != null && option.time.length) {
					option.startDate = option.time[0];
					option.endDate = option.time[1];
				} else {
					option.startDate = "";
					option.endDate = "";
				}
				this.$http
					.post(url, option, { type: "format", isExcel: true })
					.then((res) => {
						this.$util.funDownload(res.data, "车辆违规明细");
					});
			},
			formarViolationValue(row, column, cellValue) {
				console.log("+++++++++++++++++++++++++++++");
				if (
					row["violationTypeName"] === "超速行车" ||
					row["violationTypeName"] === "超速报警扩展" ||
					row["violationTypeName"] === "低速行车"
				) {
					return cellValue + "(0.01km/h)";
				} else if (
					row["violationTypeName"] === "甩站" ||
					row["violationTypeName"] === "滞站"
				) {
					return cellValue + "(s)";
				} else if (
					row["violationTypeName"] === "车内温度过高" ||
					row["violationTypeName"] === "车内温度过低"
				) {
					return cellValue + "(℃)";
				} else if (
					row["violationTypeName"] === "急加速" ||
					row["violationTypeName"] === "急刹车"
				) {
					return cellValue + "(0.01m/s2)";
				} else if (row["violationTypeName"] === "疲劳驾驶") {
					return cellValue + "(min)";
				} else {
					return cellValue;
				}
			},
		},
	};
</script>
